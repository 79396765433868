<template>
    <section class="main-container">
        <section class="title-container">
            <h1>
                <slot name="title"/>
            </h1>
            <h2>
                <slot name="text"/>
            </h2>
            <slot name="extra"/>
        </section>
        <img :src="img" alt="Illustration" :class="inverted !== undefined ? 'title-container-inverted' : undefined"/>
    </section>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class AchievementTitle extends Vue {
    @Prop
    private img? : string;

    @Prop
    private inverted? : boolean;
}
</script>

<style scoped>

h1 {
    color: var(--secondary-color);
    font-size: 3em;
    margin-bottom: 20px;
}

h2 {
    color: var(--secondary-color);
    opacity: 0.8;
    font-size: 1.6em;
    font-weight: normal;
}

img {
    height: 400px;
    margin-right: 10%;
}

.main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: start;

    margin: 5% 10%;
}

.title-container-inverted {
    order:-1 ;
    margin: 5% 0 5% 10%;
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
    img {
        height: 500px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1299px) {
    img {
        height: 400px;
    }
}

@media screen and (max-width: 999px) {
    img {
        height: 200px;
        margin: 0;
    }

    .title-container-inverted {
        order:-1 ;
        margin: 0;
    }

    .main-container {
        flex-direction: column;
        align-items: center;

        margin-top: 30px;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1em;
    }

    h1,h2 {
        text-align: center;
    }

}
</style>