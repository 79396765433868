<template>
    <div class="fishing-tile-main-container">
        <section>
            <p>{{ text }}</p>
            <custom-button text="Nous contacter" href="https://iaca-electronique.com/html/contact.html" new-tab/>
        </section>
    </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import CustomButton from "@/components/ui/CustomButton.vue";
import AnimatedBackgroundSquare from "@/components/ui/AnimatedBackgroundSquare.vue";

@Component({
    components: {AnimatedBackgroundSquare, CustomButton}
})
export default class FishingTile extends Vue {
    @Prop
    private text?: string;

}
</script>


<style scoped>

.fishing-tile-main-container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 20px;
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.33);

    background: rgb(6,86,238);
    background: linear-gradient(16deg, rgba(6,86,238,1) 0%, rgb(70, 135, 251) 100%);

    padding: 20px;
}

.fishing-tile-main-container section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.fishing-tile-main-container section p {
    color: white;
    font-size: 1.8em;
    text-align: center;
    font-weight: bold;

    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .fishing-tile-main-container section p {
        font-size: 1.3em;
    }
}

</style>