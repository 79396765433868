<template>
    <canvas id="particle-canvas"></canvas>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import {isNotUndefined} from "@/utils/Types";

const NUM_PARTICLES = 600;
const PARTICLE_SIZE = 0.5; // View heights
const SPEED = 30000; // Milliseconds

@Component({})
export default class AnimatedBackground extends Vue {

    private animationId?: number;
    private particles: any = [];

    // ***************************************************************************************************************

    private normalPool(o: any) {
        let r = 0;
        do {
            const a = Math.round(this.randomNormal({mean: o.mean, dev: o.dev}));
            if (a < o.pool.length && a >= 0) return o.pool[a];
            r++
        } while (r < 100)
    }

    private rand(low: number, high: number) {
        return Math.random() * (high - low) + low;
    }

    private  randomNormal(o: any) {
        if (o = Object.assign({
            mean: 0,
            dev: 1,
            pool: []
        }, o), Array.isArray(o.pool) && o.pool.length > 0) return this.normalPool(o);
        let r, a, n, e, l = o.mean, t = o.dev;
        do {
            r = (a = 2 * Math.random() - 1) * a + (n = 2 * Math.random() - 1) * n
        } while (r >= 1);
        return e = a * Math.sqrt(-2 * Math.log(r) / r), t * e + l
    }

    private createParticle() {
        const colour = {
            r: 6,
            g: this.randomNormal({mean: 86, dev: 20}),
            b: 238,
            a: this.rand(0, 1),
        };
        return {
            x: -2,
            y: -2,
            diameter: Math.max(0, this.randomNormal({mean: PARTICLE_SIZE, dev: PARTICLE_SIZE / 2})),
            duration: this.randomNormal({mean: SPEED, dev: SPEED * 0.1}),
            amplitude: this.randomNormal({mean: 16, dev: 2}),
            offsetY: this.randomNormal({mean: 0, dev: 10}),
            arc: Math.PI * 2,
            startTime: performance.now() - this.rand(0, SPEED),
            colour: `rgba(${colour.r}, ${colour.g}, ${colour.b}, ${colour.a})`,
        }
    }

    private moveParticle(particle: any, canvas: HTMLCanvasElement, time: number) {
        const progress = ((time - particle.startTime) % particle.duration) / particle.duration;
        return {
            ...particle,
            x: progress,
            y: ((Math.sin(progress * particle.arc) * particle.amplitude) + particle.offsetY),
        };
    }

    private drawParticle(particle: any, ctx: any) {
        const canvas = document.getElementById('particle-canvas') as HTMLCanvasElement;
        const vh = canvas.height / 100;

        ctx.fillStyle = particle.colour;
        ctx.beginPath();
        ctx.ellipse(
            particle.x * canvas.width,
            particle.y * vh + (canvas.height / 2),
            particle.diameter * vh,
            particle.diameter * vh,
            0,
            0,
            2 * Math.PI
        );
        ctx.fill();
    }

    private initializeCanvas() {
        let canvas = document.getElementById('particle-canvas') as HTMLCanvasElement;
        canvas.width = canvas.offsetWidth * window.devicePixelRatio;
        canvas.height = canvas.offsetHeight * window.devicePixelRatio;
        let ctx = canvas.getContext("2d");

        window.addEventListener('resize', () => {
            canvas.width = canvas.offsetWidth * window.devicePixelRatio;
            canvas.height = canvas.offsetHeight * window.devicePixelRatio;
            ctx = canvas.getContext("2d");
        })

        return [canvas, ctx];
    }


    mounted() {
        const [canvas, ctx] = this.initializeCanvas();

        // Create a bunch of particles
        for (let i = 0; i < NUM_PARTICLES; i++) {
            this.particles.push(this.createParticle());
        }

        this.animationId = requestAnimationFrame((time) => this.draw(time, canvas as HTMLCanvasElement, ctx));
    }

    private draw(time: number, canvas: HTMLCanvasElement, ctx: any) {
        // Move particles
        this.particles.forEach((particle: any, index: number) => {
            this.particles[index] = this.moveParticle(particle, canvas, time);
        })

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the particles
        this.particles.forEach((particle: any) => {
            this.drawParticle(particle, ctx);
        })

        // Schedule next frame
        this.animationId = requestAnimationFrame((time) => this.draw(time, canvas, ctx));
    }

    unmounted() {
        if (isNotUndefined(this.animationId))
            cancelAnimationFrame(this.animationId!);
    }

}
</script>


<style scoped>
#particle-canvas {
    position: absolute;

    width: 100%;
    height: 100vh;

    vertical-align: middle;
}
</style>