<template>

    <section>
        <h3>Technologies</h3>
        <div class="images-container">
            <img v-for="image in images" :src="image" alt="Image">
        </div>
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class AchievementTechnologiesList extends Vue {

    @Prop
    private images?: string[];

}
</script>

<style scoped>

section {
    width: 100%;

    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(77, 136, 255, 0.15) 0%, rgba(6, 86, 238, 0.25) 100%);

    padding: 20px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section h3 {
    color: var(--secondary-color);
    margin-bottom: 20px;
    font-size: 2.5em;
}

section .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    gap: 20px;

    width: 90%;
}

.images-container img {
    height: 80px;
    transition: transform 200ms;
}

.images-container img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1000px) {
    .images-container img {
        height: 50px;
    }
}

</style>