<template>
    <section>

        <h3>{{ title }}</h3>

        <div>
            <slot name="video"/>
        </div>
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class AchievementVideoContainer extends Vue {
    @Prop
    private title?: string;
}
</script>

<style scoped>

section {

    width: 100%;

    padding: 20px 0;

    background: linear-gradient(16deg, rgba(6,86,238,1) 0%, rgb(70, 135, 251) 100%);


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section h3 {
    color: white;
    font-size: 2em;
    margin-bottom: 20px;
}
</style>