<template>


    <section class="main-container">
        <section class="title-container">
            <h1>Nos services</h1>
            <h2>Au fil des projets, notre expertise ne cesse de croitre, nous permettant ainsi de vous proposer toute
                une liste de compétences pour réaliser vos projets.</h2>

        </section>

        <section class="skill-tile-container">
            <skill-tile mdi-icon="mdi-application-outline" title="Application WEB"
                        details="Développement, hébergement et maintenance de vos applications web."/>
            <skill-tile mdi-icon="mdi-web" title="Site vitrine"
                        details="Améliorer la visibilité de votre commerce/entreprise grâce à votre propre site 100% sur mesure."/>
            <skill-tile mdi-icon="mdi-api" title="API"
                        details="Développement, hébergement et maintenance d'API pour vos différents projets technologiques. "/>
            <skill-tile mdi-icon="mdi-linux" title="Linux"
                        details="Développement de projets basés sur notre plateforme IACA-BOX avec un OS Linux."/>

        </section>

        <section class="button-container">
            <custom-button type="primary" text="Nous contacter" href="https://iaca-electronique.com/html/contact.html" new-tab/>
            <custom-button v-if="false" type="secondary" text="Faire un devis"/>
        </section>

    </section>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import SkillTile from "@/components/ui/SkillTile.vue";
import CustomButton from "@/components/ui/CustomButton.vue";

@Component({
    components: {CustomButton, SkillTile}
})
export default class SkillsContainer extends Vue {

}
</script>

<style scoped>

section.main-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-image: url("@/assets/img/5860570_3017085-transformed.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    opacity: 0.15;
}

section.main-container {
    width: 100vw;

    position: relative;

    background-color: #0a163e;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

section.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 10;
}

.title-container h1 {
    font-weight: normal;
    color: white;
    text-align: center;

    font-size: 3em;
}

.title-container h2 {
    font-weight: normal;
    color: white;
    text-align: center;

    font-size: 1.1em;

    opacity: 0.8;
}

section.skill-tile-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;

    margin-top: 60px;

    z-index: 10;
}

section.button-container {
    width: fit-content;
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin-top: 60px;
    margin-bottom: 20px;

    z-index: 10;
}

@media screen and (max-width: 450px) {
    .title-container h1 {
        font-size: 2.3em;
    }

    .title-container h2 {
        font-size: 0.9em;
    }

    section.skill-tile-container {
        gap: 40px;
    }
}

</style>