<template>

    <section class="achievement-tile-container"
             :class="type === 'big' ? 'achievement-tile-big-type' : 'achievement-tile-normal-type'">
        <section>
            <div class="text-infos">
                <h1>{{ title }}</h1>
                <div class="subtext-infos">
                    <h2>{{ text }}</h2>
                    <div class="badges-container">
                        <slot name="badges"/>
                    </div>
                </div>
            </div>

            <a class="mt-5" v-if="hideLink === undefined" :href="url">En savoir plus</a>
        </section>

        <div class="image-container">
            <img :src="img" alt="Illustration">
        </div>
    </section>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class AchievementTile extends Vue {
    @Prop
    private title?: string;
    @Prop
    private text?: string;
    @Prop
    private url?: string;
    @Prop
    private img?: string;
    @Prop
    private type?: string;
    @Prop
    private hideLink?: boolean;
}
</script>

<style scoped>

.achievement-tile-container {
    background-color: white;
    border-radius: 20px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(6, 86, 238, 0.15);

    padding: 20px;
}

.achievement-tile-big-type {
    flex-direction: column;
    justify-content: start;
}


.achievement-tile-big-type section {
    order: 1;
}

.achievement-tile-container .text-infos {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 80% auto;
    height: 100%;
    width: 100%;
}

.achievement-tile-container h1 {
    font-size: 1.7em;
    color: var(--secondary-color);
}

.achievement-tile-container.achievement-tile-big-type h1 {
    margin-bottom: 10px;
    margin-top: 5px;
}

.achievement-tile-container .subtext-infos {
    height: 100%;
}

.achievement-tile-container .subtext-infos .badges-container {
    width: 100%;
    display: flex;
    gap: 5px;
}

.achievement-tile-container h2 {
    font-size: 0.9em;
    font-weight: normal;
    opacity: 0.8;

    overflow: hidden;
}

.achievement-tile-container a {
    font-size: 0.9em;
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
}


.achievement-tile-container section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.achievement-tile-container.achievement-tile-normal-type section {
    width: calc(100% - 250px);
}

.achievement-tile-container.achievement-tile-big-type section {
    height: calc(100% - 200px);
}

.achievement-tile-container .image-container {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    border-radius: 20px;
}

.achievement-tile-container.achievement-tile-normal-type .image-container {
    width: 200px;
    height: 100%;
}

.achievement-tile-container.achievement-tile-big-type .image-container {
    order: 0;
    width: 100%;
    height: 200px;
}

.achievement-tile-container.achievement-tile-normal-type .image-container img {
    height: 100%;
}

.achievement-tile-container.achievement-tile-big-type .image-container img {
    width: 100%;
}


</style>