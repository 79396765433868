<template>

    <div class="skill-tile">
        <div class="tile-icon-container">
            <v-icon class="tile-icon" size="40">{{ mdiIcon }}</v-icon>
        </div>

        <h2>{{ title }}</h2>

        <div class="separator"/>

        <h3>{{ details }}</h3>

    </div>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class SkillTile extends Vue {

    @Prop
    private mdiIcon?: string;
    @Prop
    private title?: string;
    @Prop
    private details?: string;

}
</script>

<style scoped>

.skill-tile {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tile-icon-container {
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    opacity: 0.8;
}

.tile-icon {
    color: var(--primary-color);
}

.skill-tile h2 {
    color: white;
    font-weight: normal;
    margin-top: 5px;
}

.separator {
    background-color: white;
    opacity: 0.25;
    height: 2px;
    width: 40px;
}

.skill-tile h3 {
    color: white;
    opacity: 0.8;
    text-align: center;
    font-weight: normal;
}

@media screen and (max-width: 390px) {
    .skill-tile {
        width: 300px;
    }
}

</style>