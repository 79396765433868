<template>


    <section class="tile-container">
        <div v-if="newest !== undefined" class="new-chip">Nouveau !</div>
        <div class="icon-container">
            <img :src="img" alt="Secteur illustration" />
        </div>
        <h2>{{ title }}</h2>
    </section>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class SectorTile extends Vue {

    @Prop
    private img? : string;

    @Prop
    private title? : string;

    @Prop
    private newest? : boolean;


}
</script>

<style scoped>

.tile-container {
    width: 300px;
    height: 70px;

    box-shadow: 0 0 20px 3px rgba(0, 0, 0, .25);
    border-radius: 20px;

    display: grid;
    grid-template-columns: 75px calc(300px - 75px);
    align-items: center;

    transition: transform 250ms;

    background-color: white;

    position: relative;
}

.tile-container:hover {
    transform: scale(1.02);
}

.tile-container h2 {
    font-size: 1em;
}

.tile-container .icon-container {
    height: 45px;
    width: 45px;
    margin-left: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--primary-color);

    border-radius: 50px;
}

.tile-container img {
    height: 30px;

    filter: invert(1);
}

.new-chip {
    position: absolute;
    top: -10px;
    left: -25px;

    font-size: 0.85em;

    background-color: var(--primary-color);
    color: white;
    padding: 0 5px;
    border-radius: 5px;

    box-shadow: 0 0 8px 3px rgba(0, 0, 0, .25);
}


</style>