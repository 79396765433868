<template>
    <section class="main-container">
        <section class="text-container">
            <h2>
                <slot name="title"/>
            </h2>
            <h3>
                <slot name="text"/>
            </h3>
        </section>
        <img :src="img" alt="Illustration"
             :class="{ 'order-inverted': inverted !== undefined,
             'rounded': imgRounded !== undefined,
              'shadowed': imgShadowed !== undefined}"
        />
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class AchievementSection extends Vue {
    @Prop
    private img? : string;

    @Prop
    private inverted? : boolean;

    @Prop
    private imgRounded? : boolean;

    @Prop
    private  imgShadowed? : boolean;
}
</script>

<style scoped>
h2 {
    color: var(--secondary-color);
    font-size: 2em;
    margin-bottom: 20px;
}

h3 {
    color: var(--secondary-color);
    opacity: 0.9;
    font-size: 1em;
    font-weight: normal;
}

img {
    height: 400px;
    margin-right: 10%;

    transition: transform 200ms;
}

img.rounded {
    border-radius: 30px;
}

img.shadowed {
    box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.36);
}

img:hover {
    transform: scale(1.02);
}

.main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: start;

    margin: 5% 10%;
}

.order-inverted {
    order:-1 ;
    margin: 5% 0 5% 10%;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
    img {
        height: 300px;

    }
}


@media screen and (max-width: 999px) {
    img {
        height: 200px;
        margin: 0;
        order: -2;
    }

    .main-container {
        flex-direction: column;
        align-items: center;

        margin-top: 30px;
    }

    h2,h3 {
        text-align: center;
    }

    .order-inverted {
        margin: 0;
    }

}
</style>