import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05a1ca03"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.img,
      alt: "Illustration",
      class: _normalizeClass({inverted : _ctx.inverted})
    }, null, 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["text-container", {inverted : _ctx.inverted}])
    }, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 2)
  ]))
}