import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1affe56e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["target", "href"]
const _hoisted_2 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("a", {
    target: _ctx.needToOpenNewTab ? '_blank' : undefined,
    href: _ctx.href,
    class: _normalizeClass(["btn", _ctx.type === 'primary' ? 'primary' : 'secondary'])
  }, [
    _createElementVNode("a", {
      href: _ctx.href,
      target: _ctx.needToOpenNewTab ? '_blank' : undefined
    }, _toDisplayString(_ctx.text), 9, _hoisted_2),
    (_ctx.mdiIcon !== undefined)
      ? (_openBlock(), _createBlock(_component_v_icon, {
          key: 0,
          color: _ctx.type === 'primary' ? 'white' : 'blue'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.mdiIcon), 1)
          ]),
          _: 1
        }, 8, ["color"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}