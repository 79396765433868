<template>
    <section class="main-container">
        <img :src="img" alt="Illustration" :class="{inverted : inverted}">
        <div class="text-container" :class="{inverted : inverted}">
            <h2>{{ title }}</h2>
            <p>
                <slot/>
            </p>
        </div>
    </section>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class DetailsElement extends Vue {

    @Prop
    private title?: string;
    @Prop
    private inverted?: boolean;
    @Prop
    private img?: string;

}
</script>


<style scoped>

section.main-container {

    margin: 30px 10px;


    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.main-container .text-container {
    width: 60%;
    color: var(--secondary-color);
}

.main-container .text-container.inverted {
    order: 1;
}

.main-container img {
    height: 300px;
}

.main-container img.inverted {
    order: 2;
}

.text-container h2,
.text-container h3 {
    font-weight: normal;
}

.text-container h2 {
    font-size: 3em;
    margin-bottom: 20px;
}

.text-container p {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1000px) {

    section.main-container {

        width: 100%;
        margin: 20px 0;


        display: flex;
        flex-direction: column;
    }


    .text-container h2{
        font-size: 2em;
        text-align: center;
    }

    .main-container .text-container {
        width: 80%;
    }


}

</style>