import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53d728bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-center" }
const _hoisted_2 = { class: "achievements-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_achievement_home_title = _resolveComponent("achievement-home-title")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_achievement_tile = _resolveComponent("achievement-tile")!
  const _component_fishing_tile = _resolveComponent("fishing-tile")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nav_bar),
    _createVNode(_component_achievement_home_title, { class: "mt-10" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_achievement_tile, {
          class: "achmt-tile",
          id: "at-4",
          title: "Réducteur d'image Linux",
          text: "Optimisez vos fichiers image sous Linux avec notre outil de réduction. Il diminue leur\n                 taille sans altérer les données, tout en économisant de l'espace disque. Facile à intégrer\n                 et rapide à utiliser, il garantit une gestion efficace sans compromettre la qualité. Libérez\n                  des ressources et boostez vos performances dès maintenant !",
          img: require('@/assets/img/shrinker-avatar-2.jpeg'),
          type: _ctx.isFine ?  'big' : undefined,
          url: "/achievement/image-shrinker"
        }, {
          badges: _withCtx(() => [
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "elevated",
              color: "black",
              "prepend-icon": "mdi-github",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toShrinkerGithub())),
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Github ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "red",
              "prepend-icon": "mdi-console"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Bash ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["img", "type"]),
        _createVNode(_component_achievement_tile, {
          class: "achmt-tile",
          id: "at-1",
          title: "Analyse de capteur en temps réel",
          text: "Découvrez notre solution innovante pour la collecte et l'analyse de données provenant de multiples\n                 magnétomètres. Grâce à notre système avancé, les données sont efficacement traitées et affichées,\n                 facilitant la conception d'algorithmes complexes pour développer des logiques métier sophistiquées.\n                  Concrètement, notre technologie permet de détecter la présence d'un véhicule avec une précision inégalée\n                   en utilisant un magnétomètre.",
          img: require('@/assets/img/achievement_1.jpeg'),
          type: _ctx.isFine ?  'big' : undefined,
          url: "/achievement/magneto-sensor"
        }, {
          badges: _withCtx(() => [
            _createVNode(_component_v_chip, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toMPVideo())),
              class: "cursor-pointer",
              "prepend-icon": "mdi-play-circle",
              variant: "elevated",
              density: "compact",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" DEMO VIDEO ! ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Web app ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "orange"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" API ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["img", "type"]),
        _createVNode(_component_achievement_tile, {
          class: "achmt-tile",
          id: "at-2",
          title: "Gestionnaire de documentation ISO",
          text: "En réponse à nos exigences internes pour l'obtention de la norme ISO 9001, nous avons développé\n                un outil performant de gestion documentaire. Cette solution permet de stocker et de gérer efficacement\n                 tous les documents liés à la norme, en assurant une conformité totale avec ses directives.",
          img: require('@/assets/img/4300250_17973.jpg'),
          type: _ctx.isFine ?  'big' : undefined,
          "hide-link": ""
        }, {
          badges: _withCtx(() => [
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Web app ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["img", "type"]),
        _createVNode(_component_achievement_tile, {
          class: "achmt-tile",
          id: "at-3",
          title: "Programmation de masse de systèmes Linux",
          text: "Pour optimiser nos processus, nous avons développé un système permettant de programmer simultanément jusqu'à\n                 16 systèmes. Cette avancée nous a permis de multiplier notre capacité\n                  de production quotidienne.",
          img: require('@/assets/img/Designer (10).jpeg'),
          type: _ctx.isFine ?  'big' : undefined,
          "hide-link": ""
        }, {
          badges: _withCtx(() => [
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Web app ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "orange"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" API ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_chip, {
              density: "compact",
              variant: "outlined",
              color: "red"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Linux ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["img", "type"]),
        _createVNode(_component_fishing_tile, {
          class: "fishing-tile",
          text: "Ça vous plaît ? N'hésitez pas à nous contacter pour nous parler de votre projet !"
        })
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}