<template>
    <nav-bar/>
    <achievement-home-title class="mt-10"/>

    <div class="d-flex align-center justify-center">
        <section class="achievements-container">

            <achievement-tile
                class="achmt-tile"
                id="at-4"
                title="Réducteur d'image Linux"
                text="Optimisez vos fichiers image sous Linux avec notre outil de réduction. Il diminue leur
                 taille sans altérer les données, tout en économisant de l'espace disque. Facile à intégrer
                 et rapide à utiliser, il garantit une gestion efficace sans compromettre la qualité. Libérez
                  des ressources et boostez vos performances dès maintenant !"
                :img="require('@/assets/img/shrinker-avatar-2.jpeg')"
                :type="isFine ?  'big' : undefined"
                url="/achievement/image-shrinker"
            >
                <template v-slot:badges>
                    <v-chip density="compact" variant="elevated" color="black" prepend-icon="mdi-github" @click="toShrinkerGithub()" class="cursor-pointer">
                        Github
                    </v-chip>

                    <v-chip density="compact" variant="outlined" color="red" prepend-icon="mdi-console">
                        Bash
                    </v-chip>
                </template>

            </achievement-tile>

            <achievement-tile
                class="achmt-tile"
                id="at-1"
                title="Analyse de capteur en temps réel"
                text="Découvrez notre solution innovante pour la collecte et l'analyse de données provenant de multiples
                 magnétomètres. Grâce à notre système avancé, les données sont efficacement traitées et affichées,
                 facilitant la conception d'algorithmes complexes pour développer des logiques métier sophistiquées.
                  Concrètement, notre technologie permet de détecter la présence d'un véhicule avec une précision inégalée
                   en utilisant un magnétomètre."
                :img="require('@/assets/img/achievement_1.jpeg')"
                :type="isFine ?  'big' : undefined"
                url="/achievement/magneto-sensor"
            >
                <template v-slot:badges>
                    <v-chip @click="toMPVideo()" class="cursor-pointer" prepend-icon="mdi-play-circle" variant="elevated" density="compact" color="primary">
                        DEMO VIDEO !
                    </v-chip>

                    <v-chip density="compact" variant="outlined" color="primary">
                        Web app
                    </v-chip>

                    <v-chip density="compact" variant="outlined" color="orange">
                        API
                    </v-chip>
                </template>

            </achievement-tile>

            <achievement-tile
                class="achmt-tile"
                id="at-2"
                title="Gestionnaire de documentation ISO"
                text="En réponse à nos exigences internes pour l'obtention de la norme ISO 9001, nous avons développé
                un outil performant de gestion documentaire. Cette solution permet de stocker et de gérer efficacement
                 tous les documents liés à la norme, en assurant une conformité totale avec ses directives."
                :img="require('@/assets/img/4300250_17973.jpg')"
                :type="isFine ?  'big' : undefined"
                hide-link
            >
                <template v-slot:badges>
                    <v-chip density="compact" variant="outlined" color="primary">
                        Web app
                    </v-chip>
                </template>
            </achievement-tile>

            <achievement-tile
                class="achmt-tile"
                id="at-3"
                title="Programmation de masse de systèmes Linux"
                text="Pour optimiser nos processus, nous avons développé un système permettant de programmer simultanément jusqu'à
                 16 systèmes. Cette avancée nous a permis de multiplier notre capacité
                  de production quotidienne."
                :img="require('@/assets/img/Designer (10).jpeg')"
                :type="isFine ?  'big' : undefined"
                hide-link
            >
                <template v-slot:badges>
                    <v-chip density="compact" variant="outlined" color="primary">
                        Web app
                    </v-chip>

                    <v-chip density="compact" variant="outlined" color="orange">
                        API
                    </v-chip>

                    <v-chip density="compact" variant="outlined" color="red">
                        Linux
                    </v-chip>
                </template>
            </achievement-tile>

            <fishing-tile
                class="fishing-tile"
                text="Ça vous plaît ? N'hésitez pas à nous contacter pour nous parler de votre projet !"
            />
        </section>
    </div>


    <Footer/>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavBar from "@/components/ui/NavBar.vue";
import Footer from "@/components/ui/Footer.vue";
import AchievementHomeTitle from "@/components/ui/AchievementHomeTitle.vue";
import AchievementTile from "@/components/ui/AchievementTile.vue";
import FishingTile from "@/components/ui/FishingTile.vue";

@Component({
    components: {FishingTile, AchievementTile, AchievementHomeTitle, Footer, NavBar}
})
export default class AchievementsView extends Vue {

    private isFine: boolean = false;

    mounted() {
        document.body.style.backgroundColor = '#ececec'; // change this to your desired color

        window.addEventListener('resize', ev => {
            this.isFine = window.innerWidth <= 800;
        });

        this.isFine = window.innerWidth <= 800;
    }

    // ---------------------------------------------------------------------------------------------------------------

    private toMPVideo(){
        window.location.href = '/achievement/magneto-sensor#video'
    }

    private toShrinkerGithub(){
        window.open('https://github.com/IACA-Dev/iaca-image-shrinker', '_blank');
    }
}
</script>

<style scoped>

.achievements-container {
    --gap: 60px;

    display: grid;
    grid-template-columns: calc(50% - (var(--gap) / 2)) calc(50% - (var(--gap) / 2));
    grid-template-rows: 350px 250px;
    gap: var(--gap);
    width: 90%;
}

#at-1 {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
}

#at-2 {
    grid-column-start: 1;
    grid-row-start: 2;
}

#at-3 {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 2;
}

#at-4 {
    grid-column-start: 1;
    grid-row-start: 1;
}

.fishing-tile {
    margin-top: 20px;
    margin-bottom: 20px;

    grid-column-start: 1;
    grid-column-end: 3;
}

@media screen and (max-width: 1500px) {
    .achievements-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .achmt-tile {
        height: 300px;
    }
}

@media screen and (max-width: 800px) {
    .achmt-tile {
        height: fit-content;
    }
}

</style>