<template>
    <section class="code-section" :class="theme">
        <h1 class="mb-8" >{{ title }}</h1>
        <section class="code-value" :class="theme">
            <div class="code" id="code">
                <slot name="code" class="code"/>
            </div>
        </section>
        <div class="d-flex ga-5">
            <v-btn
                variant="outlined"
                color="black"
                class="mt-6"
                prepend-icon="mdi-clipboard-arrow-down"
                @click="copyText()">Copier texte
                <v-tooltip
                    activator="parent"
                    location="start"
                >Copie le code tel que présenté</v-tooltip>
            </v-btn>
            <v-btn
                variant="elevated"
                color="black"
                class="mt-6"
                prepend-icon="mdi-console-line"
                @click="copyCommand()">Copier commande
                <v-tooltip
                    activator="parent"
                    location="end"
                >Copie une commande prête à être executée</v-tooltip>
            </v-btn>
        </div>

    </section>

    <v-snackbar
        v-model="ui.showSuccessCopy"
        location="bottom right"
        color="green"
        :timeout="2000"
    >
        Copié
    </v-snackbar>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import {HtmlUtils} from "@/utils/HtmlUtils";
import {VNode} from "vue";

@Component({})
export default class CodeSection extends Vue {

    @Prop
    private theme?: 'white' | 'dark';
    @Prop
    private title?: string;

    // ---------------------------------------------------------------------------------------------------------------

    private readonly ui = {
        showSuccessCopy : false
    }

    // ---------------------------------------------------------------------------------------------------------------

    private copyCommand(){
        let codeToCopy = this.getCodeSlotText();
        codeToCopy = codeToCopy.replace(/\n/g, '');
        codeToCopy = codeToCopy.replace(/\t/g, '');
        HtmlUtils.copyToClipBoard(codeToCopy);
        this.ui.showSuccessCopy = true;
    }

    private copyText(){
        HtmlUtils.copyToClipBoard(this.getCodeSlotText())
        this.ui.showSuccessCopy = true;
    }

    private getCodeSlotText(): string {
        const slotContent = this.$slots.code ? this.$slots.code() : [];
        let textContent = '';

        slotContent.forEach((vnode: VNode) => {
            if (vnode.children) {
                if (typeof vnode.children === 'string') {
                    textContent += vnode.children.trim();
                }
            }
        });

        return textContent
    }

}
</script>


<style scoped>


.code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;

}


.code-value {
    padding: 10px;
    margin: 5px;
    border-radius: 20px;

    box-shadow: 2px 2px 15px 2px black;
    transition: transform 300ms;
}

.code-value.white {
    background-color: white;
    color: black;
}

.code-value.dark {
    background-color: #202023;
    color: white;
}

.code {
    font-family: monospace;
    white-space: pre-wrap;
}

</style>