import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39859249"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = {
  class: "code",
  id: "code"
}
const _hoisted_3 = { class: "d-flex ga-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", {
      class: _normalizeClass(["code-section", _ctx.theme])
    }, [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("section", {
        class: _normalizeClass(["code-value", _ctx.theme])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "code", { class: "code" }, undefined, true)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_btn, {
          variant: "outlined",
          color: "black",
          class: "mt-6",
          "prepend-icon": "mdi-clipboard-arrow-down",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyText()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Copier texte "),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "start"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Copie le code tel que présenté")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          variant: "elevated",
          color: "black",
          class: "mt-6",
          "prepend-icon": "mdi-console-line",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyCommand()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Copier commande "),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "end"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Copie une commande prête à être executée")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ], 2),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.ui.showSuccessCopy,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ui.showSuccessCopy) = $event)),
      location: "bottom right",
      color: "green",
      timeout: 2000
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Copié ")
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}