<template>

    <footer>
        <section class="main-content-container">
            <div class="sub-content-container">
                <span>IACA DEV</span>
                <custom-button text="Nous contacter" type="secondary" href="https://iaca-electronique.com/html/contact.html" new-tab/>
            </div>
            <div class="separator"/>
            <a class="discret" href="https://iaca-electronique.com/html/mentionlegales.html">Mentions légales</a>
        </section>
        <section class="author-container">
            <span>Site réalisé par IACA Electronique</span>
        </section>
    </footer>

</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import CustomButton from "@/components/ui/CustomButton.vue";

@Component({
    components: {CustomButton}
})
export default class Footer extends Vue {

}
</script>

<style scoped>

footer {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: #0a163e;

    height: 200px;
}

.sub-content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    width: 80%;

    margin-top: 50px;
}

.sub-content-container span {
    color: white;
    font-size: 2em;
}

.main-content-container a {
    color: white;
    margin-bottom: 5px;
    text-decoration: none;
}

.discret {
    opacity: 0.5;
}

.separator {
    width: 80%;
    height: 2px;
    background-color: white;
    opacity: 0.5;

    margin-bottom: 40px;
}


.author-container {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
}

.author-container span {
    color: white;
}

@media screen and (max-width: 500px) {
    .sub-content-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 15px;
    }

    .sub-content-container span {
        font-size: 1.8em;
    }

    .separator {
        width: 50%;
        margin-bottom: 25px;
    }
}

</style>