<template>
    <nav-bar />
    <home-section />
    <skills-container id="services" />
    <details-container />
    <project-container />
    <sector-container />

    <Footer />
</template>

<script lang="ts">


import {Component, Vue} from "vue-facing-decorator";
import HomeSection from "@/components/ui/HomeSection.vue";
import NavBar from "@/components/ui/NavBar.vue";
import SkillsContainer from "@/components/ui/SkillsContainer.vue";
import DetailsContainer from "@/components/ui/DetailsContainer.vue";
import ProjectContainer from "@/components/ui/ProjectContainer.vue";
import SectorContainer from "@/components/ui/SectorContainer.vue";
import Footer from "@/components/ui/Footer.vue";

@Component({
    components: {Footer, SectorContainer, ProjectContainer, DetailsContainer, SkillsContainer, NavBar, HomeSection}
})

export default class HomeView extends Vue {


    async mounted(): Promise<void> {

    }


}
</script>

<style scoped>


</style>