import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_home_section = _resolveComponent("home-section")!
  const _component_skills_container = _resolveComponent("skills-container")!
  const _component_details_container = _resolveComponent("details-container")!
  const _component_project_container = _resolveComponent("project-container")!
  const _component_sector_container = _resolveComponent("sector-container")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nav_bar),
    _createVNode(_component_home_section),
    _createVNode(_component_skills_container, { id: "services" }),
    _createVNode(_component_details_container),
    _createVNode(_component_project_container),
    _createVNode(_component_sector_container),
    _createVNode(_component_Footer)
  ], 64))
}