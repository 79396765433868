import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5fbf94a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-tile-container" }
const _hoisted_2 = { class: "preview-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "footer-container" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.img,
        alt: "Illustration de la prévisualisation"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("h3", null, _toDisplayString(_ctx.subtitle), 1),
      false
        ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
            _createVNode(_component_v_icon, { color: "white" }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-chevron-right")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}