<template>
    <a
        :target="needToOpenNewTab ? '_blank' : undefined"
        :href="href" class="btn"
        :class="type === 'primary' ? 'primary' : 'secondary'">

        <a :href="href" :target="needToOpenNewTab ? '_blank' : undefined">{{ text }}</a>
        <v-icon v-if="mdiIcon !== undefined" :color="type === 'primary' ? 'white' : 'blue'">{{ mdiIcon }}</v-icon>
    </a>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import {isNotUndefined} from "@/utils/Types";

@Component({})
export default class CustomButton extends Vue {

    @Prop
    private mdiIcon?: string;
    @Prop
    private text?: string;
    @Prop
    private type?: string;
    @Prop
    private href?: string;
    @Prop
    private newTab?: boolean;

    private needToOpenNewTab: boolean = false;

    mounted() {
        this.$watch(() => this.newTab,
            () => {
                this.needToOpenNewTab = isNotUndefined(this.newTab);
            });

        this.needToOpenNewTab = isNotUndefined(this.newTab);
    }

}
</script>


<style scoped>
.btn {

    padding: 15px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 5px;

    width: fit-content;
    cursor: pointer;

    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);

    text-decoration: none;
}

.btn img {
    height: 20px;
    width: 20px;
    filter: invert(100%);
}

.btn.primary {

    background-color: var(--primary-color);
}

.btn.primary a {
    color: white;
}

.btn.secondary {
    background-color: white;
}

.btn.secondary a {
    color: var(--primary-color);
}


.btn a {
    text-decoration: none;
}

</style>