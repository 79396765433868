<template>

    <section class="project-tile-container">

        <div class="preview-container">
            <img :src="img" alt="Illustration de la prévisualisation">
        </div>

        <div class="footer-container">
            <h2>{{ title }}</h2>
            <h3>{{ subtitle }}</h3>
            <button v-if="false">
                <v-icon color="white">mdi-chevron-right</v-icon>
            </button>
        </div>

    </section>

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';

@Component({})
export default class ProjectTile extends Vue {

    @Prop
    private img?: string;
    @Prop
    private title?: string;
    @Prop
    private subtitle?: string;
}
</script>

<style scoped>

.project-tile-container {
    --border-radius: 20px;
    --tile-width: 250px;

    width: var(--tile-width);
    height: 300px;

    display: grid;

    grid-template-rows: 200px 100px;

    box-shadow: 0 0 20px 10px rgba(0, 0, 0, .25);
    border-radius: var(--border-radius);


    transition: transform 250ms;
}

.project-tile-container:hover {
    transform: scale(1.02);
}

.project-tile-container .preview-container {
    height: 100%;
    width: 100%;

    border-top-left-radius: var(--border-radius);;
    border-top-right-radius: var(--border-radius);;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-tile-container .preview-container img {
    height: 100%;
}

.preview-container img {
    width: 100%;

    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
}

.project-tile-container .footer-container {
    height: 100%;
    background-color: white;

    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-container h2,
.footer-container h3 {
    font-weight: normal;
    margin-left: 5px;
}

.footer-container h2 {
    font-size: 1em;
}

.footer-container h3 {
    font-size: 0.75em;
    margin-bottom: 10px;
    opacity: 0.85;
}

.footer-container button {

    height: 30px;
    width: 30px;

    background-color: var(--primary-color);
    border-radius: 100%;

    position: absolute;

    left: calc(var(--tile-width) - 40px);
    bottom: 10px;
}
</style>