<template>
    <nav-bar/>

    <achievement-title
        class="mt-15"
        :img="require('@/assets/img/magneto-sensor-title.png')"
        inverted
    >
        <template v-slot:title>
            Notre projet d'analyse de <strong>capteur</strong> en <strong>temps réel</strong>
        </template>

        <template v-slot:text>
            Découvrez notre solution innovante pour la collecte et l'analyse de données provenant de multiples
            magnétomètres. Grâce à notre système avancé, les données sont efficacement traitées et affichées,
            facilitant la conception d'algorithmes complexes pour développer des logiques métier sophistiquées.
            Concrètement, notre technologie permet de détecter la présence d'un véhicule avec une précision inégalée
            en utilisant un magnétomètre.
        </template>
    </achievement-title>

    <achievement-video-container id="video" title="Démonstration">
        <template v-slot:video>
            <iframe id="video-iframe" src="https://www.youtube.com/embed/3hqZQqxO8cU?si=N6pzWor9jh2I8jbW"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen/>
        </template>
    </achievement-video-container>

    <achievement-section
        :img="require('@/assets/img/earth.png')"
    >
        <template v-slot:title>
            Accessible de n'<strong>importe où</strong>
        </template>
        <template v-slot:text>
            Avec cette application web, les données et fonctionnalités sont accessibles de n'importe où et sur n'importe
            quel appareil, que ce soit un ordinateur, une tablette ou un téléphone.
        </template>
    </achievement-section>


    <achievement-section
        :img="require('@/assets/img/mp-login.png')"
        inverted
        img-rounded
        img-shadowed
    >
        <template v-slot:title>
            Sécurisé
        </template>
        <template v-slot:text>
            Les données sont sécurisées par une clé d'authentification, garantissant une protection optimale.
        </template>
    </achievement-section>

    <achievement-technologies-list
        :images="[
            require('@/assets/img/v-text-logo-light.svg'),
            require('@/assets/img/1200px-Node.js_logo.svg.png'),
            require('@/assets/img/Typescript_logo_2020.svg.png'),
            require('@/assets/img/black.png'),
            require('@/assets/img/Mqtt-hor.svg.png'),
            require('@/assets/img/feature-cbeb779dc53b732d404ab5c3d4c54940.png')
        ]"
        class="mb-15"

    />

    <achievement-section
        :img="require('@/assets/img/mp-list.png')"
        img-rounded
        img-shadowed
    >
        <template v-slot:title>
            Une interface <strong>simple</strong> et <strong>efficace</strong>
        </template>
        <template v-slot:text>
            Notre interface a été conçue pour offrir une expérience utilisateur optimale. Les données les plus
            importantes sont mises en avant pour un accès rapide. La liste des capteurs permet de retrouver facilement
            ceux à étudier grâce à un tri efficace par critères.
        </template>
    </achievement-section>

    <achievement-section
        :img="require('@/assets/img/mp-main.png')"
        inverted
        img-rounded
        img-shadowed
    >
        <template v-slot:title>
            Des données <strong>précises</strong>
        </template>
        <template v-slot:text>
            L'application intègre des graphiques précis qui permettent d'analyser minutieusement le comportement des
            capteurs. Cette fonctionnalité permet de développer efficacement des algorithmes répondant à diverses
            logiques métiers. Dernièrement, cette application a été utilisée pour concevoir un algorithme complexe de
            détection de présence de véhicules.
        </template>
    </achievement-section>

    <Footer/>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavBar from "@/components/ui/NavBar.vue";
import AchievementTile from "@/components/ui/AchievementTile.vue";
import AchievementHomeTitle from "@/components/ui/AchievementHomeTitle.vue";
import AchievementTitle from "@/components/ui/AchievementTitle.vue";
import AchievementVideoContainer from "@/components/ui/AchievementVideoContainer.vue";
import Footer from "@/components/ui/Footer.vue";
import AchievementSection from "@/components/ui/AchievementSection.vue";
import AchievementTechnologiesList from "@/components/ui/AchievementTechnologiesList.vue";

@Component({
    components: {
        AchievementTechnologiesList,
        AchievementSection,
        Footer, AchievementVideoContainer, AchievementTitle, AchievementHomeTitle, AchievementTile, NavBar}
})
export default class DiffusionUrbaineSensorProject extends Vue {

}
</script>

<style scoped>
strong {
    color: var(--primary-color);
    font-weight: bold;
}

#video-iframe {
    width: 700px;
    height: 315px;
}

@media screen and (max-width: 800px) {
    #video-iframe {
        width: 300px;
        height: 200px;
    }
}
</style>