import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AchievementsView from "@/views/AchievementsView.vue";
import DiffusionUrbaineSensorProject from "@/views/DiffusionUrbaineSensorProject.vue";
import IacaImageShrinkerProject from "@/views/IacaImageShrinkerProject.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView
    },
    {
        path: "/achievement",
        name: "achievements",
        component: AchievementsView
    },
    {
        path: "/achievement/magneto-sensor",
        name: "magneto-sensor",
        component: DiffusionUrbaineSensorProject
    },
    {
        path: "/achievement/image-shrinker",
        name: "image-shrinker",
        component: IacaImageShrinkerProject
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
