import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39bcfd04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fishing-tile-main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
      _createVNode(_component_custom_button, {
        text: "Nous contacter",
        href: "https://iaca-electronique.com/html/contact.html",
        "new-tab": ""
      })
    ])
  ]))
}