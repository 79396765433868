import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b02da9a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "text-container" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", null, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      _createElementVNode("h3", null, [
        _renderSlot(_ctx.$slots, "text", {}, undefined, true)
      ])
    ]),
    _createElementVNode("img", {
      src: _ctx.img,
      alt: "Illustration",
      class: _normalizeClass({ 'order-inverted': _ctx.inverted !== undefined,
             'rounded': _ctx.imgRounded !== undefined,
              'shadowed': _ctx.imgShadowed !== undefined})
    }, null, 10, _hoisted_3)
  ]))
}