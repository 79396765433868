import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a90fd312"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-infos" }
const _hoisted_2 = { class: "subtext-infos" }
const _hoisted_3 = { class: "badges-container" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "image-container" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["achievement-tile-container", _ctx.type === 'big' ? 'achievement-tile-big-type' : 'achievement-tile-normal-type'])
  }, [
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.text), 1),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "badges", {}, undefined, true)
          ])
        ])
      ]),
      (_ctx.hideLink === undefined)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "mt-5",
            href: _ctx.url
          }, "En savoir plus", 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        src: _ctx.img,
        alt: "Illustration"
      }, null, 8, _hoisted_6)
    ])
  ], 2))
}