export abstract class HtmlUtils {
    static isInElement(x: number, y: number, element: HTMLElement): boolean {
        let isIn : boolean = false;

        if(element !== null && element !== undefined) {
            const clientRects = element.getClientRects();

            if (clientRects.length > 0) {
                const rect = clientRects.item(0)!
                isIn = x > rect.x && x < rect.x + rect.width
                    && y > rect.y && y < rect.y + rect.height;
            }
        }

        return isIn;
    }

    static async copyToClipBoard(value : string){
        const input = document.createElement('input');
        input.value = value;
        input.select();

        await navigator.clipboard.writeText(value);
    }
}