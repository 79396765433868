<template>

    <section>
        <details-element
            title="Des solutions personnalisées pour votre entreprise"
            :img="require('@/assets/img/4911004_2554012.png')"
        >
            Nous sommes spécialisés dans le développement sur mesure d’applications et de solutions logicielles. Forts
            de notre expérience, nous pouvons répondre aux besoins spécifiques de votre entreprise et vous aider à
            atteindre vos objectifs technologiques.<br><br>

            Contactez-nous dès aujourd’hui pour discuter de vos projets et découvrir comment nos solutions logicielles
            sur-mesure peuvent profiter à votre entreprise !"
            <custom-button
                text="Nous contacter"
                type="primary"
                class="ma-5"
                href="https://iaca-electronique.com/html/contact.html"
                new-tab
            />
        </details-element>

        <div class="separator"/>

        <details-element
            title="Nous allons au-delà du service"
            :img="require('@/assets/img/it-engineering-isometric-composition_1284-25086.png')"
            :inverted="true"
        >
            <div>
                Nous comprenons que chaque projet est unique et nécessite une approche
                personnalisée. C'est pourquoi nous offrons une grande flexibilité dans les services informatiques que
                nous
                proposons. Que vous ayez besoin de réaliser un Proof of Concept (POC), de développer un
                Minimum Viable Product (MVP), ou de rédiger un cahier des charges précis, notre équipe d'experts est là
                pour vous accompagner à chaque étape.<br><br>

                Quelques exemples :

                <ul>
                    <li>Conseils et stratégies</li>
                    <li>Audits techniques</li>
                    <li>Rédaction de cahier des charges</li>
                    <li>Développement de "Minimum Viable Product" (MVP)</li>
                    <li>Réalisation de "Proof Of Concept" (POC)</li>
                </ul>
            </div>
        </details-element>

        <div class="separator"/>

        <details-element
            title="Expertise Complète : De l'Informatique à l'Électronique"
            :img="require('@/assets/img/6169439_23810-edited.png')"
        >
            Nous ne nous limitons pas uniquement aux prestations informatiques.
            Nous possédons également un service d'électronique hautement qualifié, capable de prendre en charge la
            conception et la réalisation de vos projets de A à Z. Grâce à notre expertise diversifiée, nous offrons
            des solutions complètes et intégrées, allant du développement logiciel à la conception matérielle.
            <br>
            <br>
            Que ce soit pour des prototypes innovants ou des produits finis, notre équipe vous accompagne à chaque
            étape pour garantir des résultats de haute qualité, répondant parfaitement à vos besoins spécifiques.
            <br>
            <br>
            Découvrez comment notre savoir-faire en informatique et en électronique peut transformer vos idées en réalité.

            <custom-button
                text="En savoir plus"
                type="primary"
                class="ma-5"
                href="https://iaca-electronique.com/html/etudeselectroniques.html"
                new-tab
            />
        </details-element>

    </section>

</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import DetailsElement from "@/components/ui/DetailsElement.vue";
import CustomButton from "@/components/ui/CustomButton.vue";

@Component({
    components: {CustomButton, DetailsElement}
})
export default class DetailsContainer extends Vue {

}
</script>

<style scoped>

section {
    margin-top: 60px;
}

.separator {
    width: 100%;
    margin-top: 75px;
}

ul {
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

</style>