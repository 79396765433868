<template>
    <nav-bar/>

    <achievement-title
        class="mt-15"
        :img="require('@/assets/img/shrinker-illustration.png')"
        inverted
    >
        <template v-slot:title>
            Réducteur d'image <strong>Linux</strong>
        </template>

        <template v-slot:text>
            Découvrez notre outil logiciel spécialement conçu pour les systèmes Linux, offrant une solution efficace
            pour réduire la taille des fichiers image sans aucune perte de données.
            Facile à intégrer dans vos processus existants, cet outil est idéal pour les entreprises
            cherchant à améliorer la performance de leurs serveurs, réduire les coûts de stockage et accélérer la
            gestion
            des images sans compromettre la qualité. Maximisez vos ressources avec simplicité et efficacité !
        </template>

        <template v-slot:extra>
            <div class="d-flex ga-5 mt-5">
                <v-chip
                    prepend-icon="mdi-github"
                    color="black"
                    size="x-large"
                    variant="elevated"
                    class="cursor-pointer"
                    @click="this.openInNewTab('https://github.com/IACA-Dev/iaca-image-shrinker')">Github
                </v-chip>

                <v-chip
                    prepend-icon="mdi-docker"
                    color="blue"
                    size="x-large"
                    variant="elevated"
                    class="cursor-pointer"
                    @click="this.openInNewTab('https://hub.docker.com/r/iacaelectronique/iaca-image-shrinker');">Docker Hub
                </v-chip>
            </div>
        </template>


    </achievement-title>

    <code-section title="Code"  theme="dark">

        <template v-slot:code>
            {{
            `docker run --privileged
 \t\t-v /dev:/dev
 \t\t-it
 \t\t-v $(pwd):/mnt
 \t\tiacaelectronique/iaca-image-shrinker:latest -o /mnt/output.img -t raspberry /mnt/2021-05-07-raspios-buster-arm64.img`
            }}
        </template>

    </code-section>
    <Footer class="mt-5" />
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavBar from "@/components/ui/NavBar.vue";
import AchievementTile from "@/components/ui/AchievementTile.vue";
import AchievementHomeTitle from "@/components/ui/AchievementHomeTitle.vue";
import AchievementTitle from "@/components/ui/AchievementTitle.vue";
import AchievementVideoContainer from "@/components/ui/AchievementVideoContainer.vue";
import Footer from "@/components/ui/Footer.vue";
import AchievementSection from "@/components/ui/AchievementSection.vue";
import AchievementTechnologiesList from "@/components/ui/AchievementTechnologiesList.vue";
import CodeSection from "@/components/ui/CodeSection.vue";

@Component({
    components: {
        CodeSection,
        AchievementTechnologiesList,
        AchievementSection,
        Footer, AchievementVideoContainer, AchievementTitle, AchievementHomeTitle, AchievementTile, NavBar
    }
})
export default class IacaImageShrinkerProject extends Vue {

    private openInNewTab(target : string) {
        window.open(target, '_blank');
    }

}
</script>

<style scoped>
strong {
    color: var(--primary-color);
    font-weight: bold;
}
</style>