<template>
    <section class="main-section">
        <div class="title-container">
            <h1>Des solutions informatiques sur-mesure pour votre entreprise</h1>

            <custom-button type="primary" text="Découvrir nos services" mdi-icon="mdi-chevron-right" href="#services" />

        </div>
        <img class="title-img" src="@/assets/img/47994102.png" alt="IT illustration">
        <animated-background class="h-100 overflow-hidden opacity-15 blur"/>
    </section>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import AnimatedBackground from "@/components/ui/AnimatedBackground.vue";
import CustomButton from "@/components/ui/CustomButton.vue";

@Component({
    components: {CustomButton, AnimatedBackground}
})
export default class HomeSection extends Vue {

}
</script>


<style scoped>

.main-section {
    color: var(--secondary-color);

    overflow: hidden;

    width: 100vw;
    height: 100vh;

    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(77, 136, 255, 0) 0%, rgba(6, 86, 238, 0.15) 100%);

    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}

.main-section .title-container {
    margin-left: 60px;
    z-index: 10;
}

.main-section h1 {
    font-weight: normal;
    font-size: 5em;
    transform: translateY(-60px);
}

.main-section img.title-img {
    width: 90%;
    z-index: 10;
}



.blur {
    filter: blur(10px);
}

@media screen and (min-width: 1001px) and (max-width: 1500px) {
    .main-section h1 {
        font-size: 4em;
    }
}

@media screen and (min-width: 500px) and (max-width: 1000px) {
    .main-section h1 {
        font-size: 3em;
    }
}

@media screen and (max-width: 500px) {
    .main-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .main-section .title-container {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .main-section h1 {
        font-size: 2em;
        text-align: center;
        transform: translateY(0);
        margin-bottom: 20px;
    }

    .main-section  img.title-img {
        transform: translateY(30px);
    }
}
</style>