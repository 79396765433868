<template>
    <section class="main-container">
        <animated-backgroud-square class="background" />
        <h1>Les secteurs qui nous font confiance</h1>
        <section class="tiles-container">
            <sector-tile
                :img="require('@/assets/img/solarpanel_4704.png')"
                title="Énergie solaire"
                newest
            />

            <sector-tile
                :img="require('@/assets/img/city_105138.png')"
                title="Urbanisme"
            />

            <sector-tile
                :img="require('@/assets/img/3986752-building-factory-industry-icon_112345.png')"
                title="Production d'hydrogène"
            />

            <sector-tile
                :img="require('@/assets/img/lockicon_120641 (1).png')"
                title="Contrôle d'accès"
            />

            <sector-tile
                :img="require('@/assets/img/electronic_5486.png')"
                title="Industrie électronique"
            />

            <sector-tile
                :img="require('@/assets/img/car_13260.png')"
                title="Automobile"
            />

            <sector-tile
                :img="require('@/assets/img/blood_droplet_health_healthcare_medic_medical_water_icon_127261.png')"
                title="Traitement de l'eau"
            />

            <sector-tile
                :img="require('@/assets/img/location_icon-icons.com_50109.png')"
                title="Géolocalisation"
            />

            <sector-tile
                :img="require('@/assets/img/3643769-building-home-house-main-menu-start_113416.png')"
                title="Domotique"
            />
        </section>
    </section>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import SectorTile from "@/components/ui/SectorTile.vue";
import AnimatedBackgroundSquare from "@/components/ui/AnimatedBackgroundSquare.vue";

@Component({
    components: {AnimatedBackgroudSquare: AnimatedBackgroundSquare, SectorTile}
})
export default class SectorContainer extends Vue {

}
</script>

<style scoped>
.main-container {
    width: 100%;
    height:max-content;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.main-container h1 {

    margin-top: 50px;

    font-weight: normal;
    font-size: 3em;
    color: var(--secondary-color);
    text-align: center;
}

.tiles-container {
    padding-top: 50px;

    display: flex;
    gap: 50px 30px;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    margin-bottom: 50px;
}

.main-container h1,
.tiles-container {
    z-index: 50;
}

.background {
    z-index: 1;
    opacity: 0.3;
}

@media screen and (min-width: 1200px) {

    .tiles-container {
        padding-top: 50px;

        display: grid;
        grid-template-columns: 33% 33% 33%;

        gap: 50px 30px;

        align-items: center;
    }
}

</style>