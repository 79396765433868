import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198d9346"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-container" }
const _hoisted_2 = {
  key: 0,
  class: "new-chip"
}
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.newest !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Nouveau !"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.img,
        alt: "Secteur illustration"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
  ]))
}