<template>
    <section class="main-container">
        <h1>Nos derniers projets</h1>
        <section class="project-tiles">
            <project-tile
                :img="require('@/assets/img/shrinker-avatar-2.jpeg')"
                title="Réducteur d'image Linux"
                subtitle="Bash & Linux"
            />
            <project-tile
                :img="require('@/assets/img/Designer (5).jpeg')"
                title="Mesure de capteur"
                subtitle="Web app & API"
            />
            <project-tile
                :img="require('@/assets/img/4300250_17973.jpg')"
                title="Gestionnaire de documentation ISO 9001"
                subtitle="Web app"
            />
            <project-tile
                :img="require('@/assets/img/Designer (10).jpeg')"
                title="Programmateur de masse Linux"
                subtitle="Web app & API & Linux"
            />
        </section>
        <custom-button type="primary" text="Voir plus" @click="$router.push({name:'achievements'})" />
    </section>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import ProjectTile from "@/components/ui/ProjectTile.vue";
import CustomButton from "@/components/ui/CustomButton.vue";

@Component({
    components: {CustomButton, ProjectTile}
})
export default class ProjectContainer extends Vue {

}
</script>

<style scoped>

.main-container {
    width: 100%;

    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(77, 136, 255, 0) 0%, rgba(6, 86, 238, 0.15) 100%);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 50px 0;
}

.main-container h1 {
    font-weight: normal;
    font-size: 3em;
    color: var(--secondary-color);
    text-align: center;
}

.project-tiles {
    width: 100%;

    margin-top: 30px;
    margin-bottom: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}


@media screen and (max-width: 1000px) {
    .project-tiles {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

</style>