<template>
    <achievement-title
        :img="require('@/assets/img/achievement_home.png')"
    >
        <template v-slot:title>
            Explorer nos réalisation !
        </template>
        <template v-slot:text>
            Découvrez nos réalisations et laissez-vous inspirer par nos solutions informatiques qui transforment
            chaque projet en <strong>succès</strong>.
        </template>
    </achievement-title>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import AchievementTitle from "@/components/ui/AchievementTitle.vue";

@Component({
    components: {AchievementTitle}
})
export default class AchievementHomeTitle extends Vue {

}
</script>

<style scoped>

strong {
    color: var(--primary-color);
    font-weight: bold;
}

</style>